import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

export const StyledTitle = styled.h1`
  color: ${props => props.theme.colors.primary};
  font-size: 4.8rem;
  margin-top: 4.8rem;
  margin-bottom: 1.6rem;
  font-weight: normal;

  ${media.lessThan('medium')`
    margin-top: 3.2rem;
    font-size: 3.2rem;
  `}
`

export const StyledContent = styled.div`
  color: ${props => props.theme.colors.brownishGrey};
  font-size: 1.6rem;
`

export const Container = styled.div`
  margin-right: 9.6rem;
  margin-left: 9.6rem;
  margin-bottom: 9.6rem;

  ${media.lessThan('medium')`
    margin-right: 2.4rem;
    margin-left: 2.4rem;
  `}
`

export const CardStyled = styled.div`
  min-height: 24rem;
  display: flex;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    flex-direction: column;
    height: 100%;
  `}
`

export const CardCarouselStyled = styled.div`
  display: flex;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  width: 100%;

  height: 46.4rem;
  margin-bottom: 1.6rem;
`

export const InnerCardContainer = styled.div`
  padding: 2.4rem;
  width: 100%;
  position: relative;

  ${media.lessThan('medium')`
    width: auto;
  `}
`

export const InnerCardContainerCarousel = styled.div`
  padding: 2.4rem;
  width: 100%;

  ${media.lessThan('medium')`
    width: auto;
  `}
`

export const CardName = styled.h3`
  color: ${props => props.theme.colors.black};
  font-weight: normal;
  font-size: 2.4rem;
  margin: 0;
`

export const Crm = styled.p`
  color: ${props => props.theme.colors.greyishBrown};
  font-weight: normal;
  font-size: 1.4rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
`

export const MiniCv = styled.p`
  color: ${props => props.theme.colors.brownishGrey};
  font-size: 1.4rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
`

export const Picture = styled(Img)`
  min-width: 27.6rem;
  height: 24rem;
  object-fit: cover;

  ${media.lessThan('medium')`
    min-width: 100%;
  `}
`

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  grid-gap: 2.4rem;
  padding-right: 0.8rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  margin-bottom: 6.4rem;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0.8rem;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #e9e9e9;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  ${media.lessThan('large')`
    grid-template-columns: 1fr;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const SectionTitle = styled.h2`
  font-size: 3.2rem;
  color: ${props => props.theme.colors.black};
  margin-bottom: 2.8rem;
  font-weight: normal;
`

export const SelectorContainer = styled.div`
  display: flex;
`

export const Selector = styled.div`
  width: 22.7rem;
  cursor: pointer;
  color: ${props =>
    [props.theme.colors.brownishGrey, props.theme.colors.primary][Number(props.active)]};

  padding: 1.6rem;
  box-sizing: border-box;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: 2px solid
    ${props => [props.theme.colors.brownishGrey, props.theme.colors.primary][Number(props.active)]};

  &:hover {
    color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
  }
`

export const SelectorText = styled.p`
  margin: 0;
  font-size: 2.4rem;
  font-family: 'Dasa Sans';

  ${media.lessThan('medium')`
    font-size: 1.6rem;
  `}
`

export const CarouselAnchor = styled.div`
  position: relative;
  display: none;

  ${media.lessThan('medium')`
    display: block;
  `}
`
