import React, { memo } from 'react'

import Slugify from 'slug'

import {
  CardCarouselStyled,
  Picture,
  InnerCardContainerCarousel,
  CardName,
  Crm,
  MiniCv,
  SeeMoreCarousel,
  ChevronRightIcon,
} from '../styled'

const Card = ({ photo, name, crm, miniCv, title_gender, slug, slugPrefix = '/corpo-clinico/' }) => {
  return (
    <CardCarouselStyled>
      <Picture fluid={photo.localFile.childImageSharp.fluid} alt={photo.alt_text} />
      <InnerCardContainerCarousel>
        <CardName>
          {title_gender} {name}
        </CardName>
        <Crm>{crm}</Crm>
        <MiniCv>{miniCv}</MiniCv>
      </InnerCardContainerCarousel>
    </CardCarouselStyled>
  )
}

export default memo(Card)
