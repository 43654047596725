import React, { useState, createContext } from 'react'

const PageContext = createContext()

export const PageProvider = props => {
  const [_page, _setPage] = useState(0)

  const setPage = page => _setPage(page)
  const getPage = () => _page

  return (
    <PageContext.Provider
      value={{
        setPage,
        getPage,
      }}
      {...props}
    />
  )
}

export const usePage = () => {
  const context = React.useContext(PageContext)
  if (context === undefined) {
    throw new Error('usePage must be used within a PageProvider')
  }
  return context
}
