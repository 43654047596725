import React, { memo } from 'react'

import Slugify from 'slug'

import {
  CardStyled,
  Picture,
  InnerCardContainer,
  CardName,
  Crm,
  MiniCv,
  SeeMore,
  ChevronRightIcon,
} from '../styled'

const Card = ({ photo, name, crm, miniCv, title_gender, slug, slugPrefix = '/corpo-clinico/' }) => {
  return (
    <CardStyled fade="in">
      <Picture fluid={photo.localFile.childImageSharp.fluid} alt={photo.alt_text} />

      <InnerCardContainer>
        <CardName>
          {title_gender} {name}
        </CardName>
        <Crm>{crm}</Crm>
        <MiniCv>{miniCv}</MiniCv>
      </InnerCardContainer>
    </CardStyled>
  )
}

export default memo(Card)
