import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/seo'

import {
  Title,
  Content,
  SpotlightStaff,
  StateSelector,
} from '../components/ClinicalStaff/Dedicated'
import { Container } from '../components/ClinicalStaff/Dedicated/styled'

import { GeographicalStateProvider } from '../hooks/useGeographicalState'
import { PageProvider } from '../hooks/usePage'

const Staff = ({ pageContext, location }) => {
  const data = useStaticQuery(graphql`
    {
      allWordpressWpClinicalStaffPage(sort: { fields: title }) {
        nodes {
          id
          slug
          title
          acf {
            acting_region
            council_number
            specialization_area
            title_gender
            in_spotlight {
              resume
              function_description
              picture {
                alt_text
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
      wordpressWpGatsbyPage {
        acf {
          staff_info
          staff_title
          staff_working_hours
          title
          description
          canonical
        }
      }
      settings: wordpressAltaThemeSettings {
        brand_name
      }
    }
  `)

  const allClinicalStaff = data.allWordpressWpClinicalStaffPage.nodes
  const allClinicalStaffPage = data.wordpressWpGatsbyPage.acf
  const settings = data.settings
  const spotlightStaff = allClinicalStaff

  return (
    <Layout pageContext={pageContext} location={location}>
      <SEO
        title={`${allClinicalStaffPage.title} | ${settings.brand_name}`}
        description={allClinicalStaffPage.description}
        canonical={allClinicalStaffPage.canonical}
      />
      <Container>
        <Title>{allClinicalStaffPage.staff_title}</Title>

        <Content html={allClinicalStaffPage.staff_info} />

        <PageProvider>
          <GeographicalStateProvider>
            <StateSelector />

            <SpotlightStaff staff={spotlightStaff} />
          </GeographicalStateProvider>
        </PageProvider>

        <Content html={allClinicalStaffPage.staff_working_hours} />
      </Container>
    </Layout>
  )
}

export default Staff
