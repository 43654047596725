import React from 'react'

import { SelectorContainer, Selector, SelectorText } from './styled'

import slug from 'slug'

import { useGeographicalState } from '../../../hooks/useGeographicalState'
import { usePage } from '../../../hooks/usePage'

const StateSelector = () => {
  const { setGeographicalState, getGeographicalState } = useGeographicalState()
  const { setPage } = usePage()

  const changeState = state => {
    if (state === getGeographicalState()) {
      return
    }
    setPage(0)
    setGeographicalState(state)
  }

  const isActive = state => state === getGeographicalState()

  return (
    <SelectorContainer>
      {['São Paulo', 'Rio de Janeiro'].map(state => (
        <Selector
          key={state}
          data-testid={`selector-${slug(state)}`}
          active={isActive(state)}
          onClick={() => changeState(state)}
        >
          <SelectorText>{state}</SelectorText>
        </Selector>
      ))}
    </SelectorContainer>
  )
}

export default StateSelector
