import React, { useState, useEffect } from 'react'
import Card from './Card'
import CardCarousel from './CardCarousel'

import { CardContainer, SectionTitle, CarouselAnchor } from '../styled'
import { useGeographicalState } from '../../../../hooks/useGeographicalState'

import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

import { ArrowLeft, ArrowRight } from '../../../shared/CarouselArrow'

const filterStaff = (staff, state) =>
  staff.filter(({ acf }) => [state, 'Ambos'].includes(acf.acting_region))

const SpotlightStaff = ({ staff }) => {
  const { getGeographicalState } = useGeographicalState()

  const filteredStaff = filterStaff(staff, getGeographicalState())

  return (
    <>
      <SectionTitle>Diretoria Médica</SectionTitle>
      <CarouselAnchor>
        <Carousel
          addArrowClickHandler
          arrowRightDisabled={<></>}
          arrowRight={<ArrowRight />}
          arrowLeftDisabled={<></>}
          arrowLeft={<ArrowLeft />}
          slidesPerPage={1}
          itemWidth={240}
          offset={24}
        >
          {filteredStaff.map(({ acf, id, title, slug }) => (
            <CardCarousel
              key={id}
              photo={acf.in_spotlight.picture}
              name={title}
              crm={acf.council_number}
              miniCv={acf.in_spotlight.function_description}
              title_gender={acf.title_gender}
              slug={slug}
            />
          ))}
        </Carousel>
      </CarouselAnchor>

      <CardContainer>
        {filteredStaff.map(({ acf, id, title, slug }) => (
          <Card
            key={id}
            photo={acf.in_spotlight.picture}
            name={title}
            crm={acf.council_number}
            miniCv={acf.in_spotlight.function_description}
            title_gender={acf.title_gender}
            slug={slug}
          />
        ))}
      </CardContainer>
    </>
  )
}

export default SpotlightStaff
